define("discourse/plugins/discourse-topic-trade-buttons/discourse/templates/connectors/category-custom-settings/topic_trade_buttons", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.topic_trade_buttons_enabled}}
    <section class="field">
      <div class="enable-sold-button">
        <label class="checkbox-label">
          {{input type="checkbox" checked=this.category.enable_sold_button}}
          {{i18n "topic_trading.enable_sold_button"}}
        </label>
      </div>
      <div class="enable-purchased-button">
        <label class="checkbox-label">
          {{input type="checkbox" checked=this.category.enable_purchased_button}}
          {{i18n "topic_trading.enable_purchased_button"}}
        </label>
      </div>
      <div class="enable-exchanged-button">
        <label class="checkbox-label">
          {{input type="checkbox" checked=this.category.enable_exchanged_button}}
          {{i18n "topic_trading.enable_exchanged_button"}}
        </label>
      </div>
      <div class="enable-cancelled-button">
        <label class="checkbox-label">
          {{input type="checkbox" checked=this.category.enable_cancelled_button}}
          {{i18n "topic_trading.enable_cancelled_button"}}
        </label>
      </div>
    </section>
  {{/if}}
  
  */
  {
    "id": "ftA2T054",
    "block": "[[[41,[30,0,[\"siteSettings\",\"topic_trade_buttons_enabled\"]],[[[1,\"  \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"enable-sold-button\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n        \"],[1,[28,[35,4],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"category\",\"enable_sold_button\"]]]]]],[1,\"\\n        \"],[1,[28,[35,5],[\"topic_trading.enable_sold_button\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"enable-purchased-button\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n        \"],[1,[28,[35,4],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"category\",\"enable_purchased_button\"]]]]]],[1,\"\\n        \"],[1,[28,[35,5],[\"topic_trading.enable_purchased_button\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"enable-exchanged-button\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n        \"],[1,[28,[35,4],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"category\",\"enable_exchanged_button\"]]]]]],[1,\"\\n        \"],[1,[28,[35,5],[\"topic_trading.enable_exchanged_button\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"enable-cancelled-button\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n        \"],[1,[28,[35,4],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"category\",\"enable_cancelled_button\"]]]]]],[1,\"\\n        \"],[1,[28,[35,5],[\"topic_trading.enable_cancelled_button\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"section\",\"div\",\"label\",\"input\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-topic-trade-buttons/discourse/templates/connectors/category-custom-settings/topic_trade_buttons.hbs",
    "isStrictMode": false
  });
});